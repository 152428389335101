import { CommonConstants, DirectBillingConstants } from "../../constants";
import { BillingApiCaller } from "../../middlewares/BillingApiCaller";
import { store } from "../../store";
import { showError } from "../commonActions";

export const getDirectBilling = async () => {
  try {
    const response = await BillingApiCaller.getBilling();
    store.dispatch({
      type: DirectBillingConstants.GET_DIRECTBILLING,
      payload: response
    });
  } catch (error) {
    error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};
