import { DirectBillingConstants } from "../../constants";
import { billingObject } from "../../interfaces";
import { BillingApiCaller } from "../../middlewares/BillingApiCaller";
import { store } from "../../store";
import { showError } from "../commonActions";

export const saveDirectBilling = async (_billingObject: billingObject): Promise<any> => {
  try {
    const response = await BillingApiCaller.saveBilling(_billingObject);
    store.dispatch({
      type: DirectBillingConstants.SAVE_DIRECTBILLING,
      payload: response
    });
    return [null, response];
  } catch (error) {
    error && showError(error);
    return [error];
  }
};
