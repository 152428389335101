import { BeneficiaryConstants, CommonConstants, ContentDataConstants, EventConfigConstants, PageConfigConstants } from "../../constants";
import { BeneficiaryApiCaller } from "../../middlewares/BeneficiaryApiCaller";
import store from "../../store/store";
import {  showError } from "../commonActions";

export const getBeneficiaries = (
  benefitID?: number,
  beneficiariesAll?: boolean,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  const flexBeneficiaryID = store.getState().beneficiary.flexBeneficiaryBenefitID;
  const link = store.getState().event.pageSetting?.link;
  const setting = typeof link !== "string" ? ["-1"] : link.split("/");
  const benefitId = benefitID
    ? benefitID
    : flexBeneficiaryID
    ? flexBeneficiaryID
    : setting[setting.length - 1];
  BeneficiaryApiCaller.getBeneficiaries(benefitId!, beneficiariesAll!)
    .then((response) => {
      store.dispatch({
        type: BeneficiaryConstants.GET_BENEFICIARIES,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      // console.log("error: ", error);
      error && saveFailedCallback && saveFailedCallback();
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getBeneficiariesAll = (
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.getBeneficiariesAll()
    .then((response) => {
      if (response) {
        store.dispatch({
          type: BeneficiaryConstants.GET_BENEFICIARIES_ALL,
          payload: response
        });
        savedCallback && savedCallback(response);
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const finishEnrollmentForms = (
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.finishEnrollmentForms()
    .then((response) => {
      store.dispatch({
        type: BeneficiaryConstants.FINISH_ENROLLMENT_FORM,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const createBeneficiary = (
  beneficiaryData: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.createBeneficiary(beneficiaryData)
    .then((response) => {
      if (response && response.statusCode > 400) {
        const error: any = [];
        error.push(response?.validationErrors ? response?.validationErrors : response?.errorMessage);
        saveFailedCallback && saveFailedCallback(error);
      } else {
        store.dispatch({
          type: BeneficiaryConstants.CREATE_BENEFICIARY,
          payload: beneficiaryData
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error?: any) => {
      saveFailedCallback && saveFailedCallback(error && error?.response?.data?.validationErrors?.length > 0 ? error?.response?.data?.validationErrors : [error?.message]);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updateBeneficiary = (
  beneficiary: any,
  contBeneficiaryList: any,
  benefit: number,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  const beneficiaries = {
    primaryBeneficiary: beneficiary,
    contingentBeneficiary: contBeneficiaryList
  };
  BeneficiaryApiCaller.updateBeneficiary(beneficiaries, benefit)
    .then((response) => {
      if (response && response.statusCode > 400) {
        const error: any = [];
        error.push(response?.validationErrors ? response?.validationErrors : response?.errorMessage);
        saveFailedCallback && saveFailedCallback(error);
      } else {
        store.dispatch({
          type: BeneficiaryConstants.UPDATE_BENEFICIARY,
          payload: { beneficiary }
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error?: any) => {
      saveFailedCallback && saveFailedCallback(error?.response?.validationErrors?.length > 0 ? error?.response?.validationErrors : error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updateIndividualBeneficiary = (
  beneficiary: any,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.updateIndividualBeneficiary(beneficiary)
    .then((response) => {
      if (response && response?.statusCode > 400) {
        showError(response?.errorMessage);
      } else {
        store.dispatch({
          type: BeneficiaryConstants.UPDATE_INDIVIDUAL_BENEFICIARY,
          payload: { beneficiary }
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error?: any) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const deleteBeneficiary = (
  beneficiaryId: any,
  benefitID: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.deleteBeneficiary(beneficiaryId, benefitID)
    .then((response) => {
      if (response && response.statusCode > 400) {
        saveFailedCallback && saveFailedCallback(response?.errorMessage);
      } else {

        store.dispatch({
          type: BeneficiaryConstants.DELETE_BENEFICIARY,
          payload: beneficiaryId
        });
          
        if (response && response?.hasOwnProperty("reallocateActionLink") && response?.reallocateActionLink != null) {
          store.dispatch({
            type: BeneficiaryConstants.SET_FLEX_BENEFICIARY_BENEFIT_ID,
            payload: -1
          });
          store.dispatch(reallocateMultiBenefit(benefitID))
        }
        savedCallback && savedCallback(response);
      }
    })
    .catch((error?: any) => {
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

/* E-Signature Start */
export const getBeneficiaryDeclaration = (
  btnNavigateTo: string,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.getBeneficiaryDeclaration(btnNavigateTo)
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_HELP_CONTENT,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryBeneficiariesESignature = (
  btnNavigateTo: string,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.getSummaryBeneficiariesESignature(btnNavigateTo)
    .then((response) => {
      if (response && response?.statusCode === 200) {
        store.dispatch({
          type: BeneficiaryConstants.GET_SUMMARY_BENEFICIARIES,
          payload: response
        });
        savedCallback && savedCallback(response);
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const summarySaveESignature = (
  isAgreed: any,
  wordTyped: any,
  beneficiaryall: any,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  console.log(isAgreed, wordTyped, beneficiaryall);
  BeneficiaryApiCaller.summarySaveESignature(isAgreed, wordTyped, beneficiaryall)
    .then((response) => {
      store.dispatch({
        type: BeneficiaryConstants.SAVE_ESIGNATURE,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error?: any) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};
/* E-Signature End */
export const reallocateMultiBenefit = (
  benefitID: number,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  const screen:any = { name: "BenefitSummary", link: "Summary" };
  if (benefitID === -2) {
    benefitID = -1;
    store.dispatch({
      type: EventConfigConstants.SET_PAGE_SETTING,
      payload: { screen, benefit: [] } 
    });
  }
  BeneficiaryApiCaller.reallocateMultiBenefit(benefitID)
    .then((response) => {
      if (response && response?.statusCode > 400) showError(response?.errorMessage);
      else {
        store.dispatch({
          type: BeneficiaryConstants.REALLOCATE_MULTIBENEFIT,
          payload: response
        });
        store.dispatch({
          type: PageConfigConstants.SET_BASE_PAGE_TITLE,
          payload: response?.tabName
        });
        if (
          response &&
          response?.result != null &&
          response?.result.length > 0
        ) {
          const resultBenefitIDs = response?.result.toString();
          store.dispatch(proceedToNextMultiBenefit(resultBenefitIDs))
        }
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryBeneficiaries = () => {
  BeneficiaryApiCaller.getSummaryBeneficiaries()
    .then((response) => {
      if (response && response?.statusCode === 200) {
        store.dispatch({
          type: BeneficiaryConstants.GET_SUMMARY_BENEFICIARIES,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const proceedToNextMultiBenefit = (
  benefitIDs: any,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  BeneficiaryApiCaller.proceedToNextMultiBenefit(benefitIDs)
    .then((response) => {
      if (response && response?.statusCode > 400) showError(response?.errorMessage);
      else {
        store.dispatch({
          type: BeneficiaryConstants.PROCEED_TO_NEXT,
          payload: response
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const setFlexBeneficiaryBenefitID = (
  benefitID: any,
  benefitTitle: any,
  savedCallback?: CallableFunction
) => {
  store.dispatch({
    type: BeneficiaryConstants.SET_FLEX_BENEFICIARY_BENEFIT_ID,
    payload: benefitID
  });
  benefitTitle && store.dispatch({
    type: PageConfigConstants.SET_BASE_PAGE_TITLE,
    payload: benefitTitle
  });
  store.dispatch({
    type: BeneficiaryConstants.GET_BENEFICIARIES,
    payload: null
  });
  savedCallback && savedCallback();
};

export const setMultiBeneficiariesList = (
  benefitList: any,
  benefitTitle: any,
  savedCallback?: CallableFunction
) => {
  store.dispatch({
    type: BeneficiaryConstants.SET_MULTI_BENEFICIARIES_LIST,
    payload: benefitList
  });

  benefitTitle && store.dispatch({
    type: PageConfigConstants.SET_BASE_PAGE_TITLE,
    payload: benefitTitle
  });
 
  savedCallback && savedCallback();
};


export const removeFlexBeneficiaryBenefitID = () => {
  store.dispatch({
    type: BeneficiaryConstants.SET_FLEX_BENEFICIARY_BENEFIT_ID,
    payload: null
  });
};
