import { EnumProfile, getMainMenuContent, getShowFlex, progressBarIndicator, setMenu2Flag, showError } from "../../actions";
import { CommonConstants, ProfileConfigConstants } from "../../constants";
import { ProfileApiCaller } from "../../middlewares/ProfileApiCaller";
import store from "../../store/store";

export const getProfileContent = async (isReview: boolean) => {
  try {
    const response = await ProfileApiCaller.getProfile(isReview);
    store.dispatch({
      type: ProfileConfigConstants.GET_PROFILE_CONTENT,
      payload: response
    });

    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      store.dispatch(await progressBarIndicator());
    }

    const menu2Flag = store.getState().common.menu2Flag;
    if (menu2Flag && (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))) {
      await getMainMenuContent(true);
      await getShowFlex();
      setMenu2Flag(false);
    }
  } catch (error) {
    error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getDependents = async (dependentType: string) => {
  try {
    const response = await ProfileApiCaller.getDependents(dependentType);
    store.dispatch({
      type: ProfileConfigConstants.GET_DEPENDENTS,
      payload: response
    });

    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      store.dispatch(await progressBarIndicator());
    }

    const menu2Flag = store.getState().common.menu2Flag;
    if (menu2Flag && (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))) {
      await getMainMenuContent(true);
      await getShowFlex();
      setMenu2Flag(false);
    }
  } catch (error) {
    error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updatePersonalInfo = async (
  profile: EnumProfile,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  try {
    const response = await ProfileApiCaller.updatePersonalInfo(profile);
    if (response?.statusCode > 400) {
      const error: any = [];
      error.push(response?.errorMessage);
      saveFailedCallback && saveFailedCallback(error);
    } else {
      store.dispatch({
        type: ProfileConfigConstants.UPDATE_PERSONAL_INFO,
        payload: response
      });
      await getProfileContent(false);
      savedCallback && savedCallback(response);
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.validationErrors || error?.message;
    saveFailedCallback && saveFailedCallback(errorMessage);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updateDependent = (
  user: any,
  dependentType: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  ProfileApiCaller.updateDependent(user, dependentType)
    .then((response) => {
      if (response?.errorMessage) {
        const error: any = [];
        error.push(response?.errorMessage);
        saveFailedCallback && saveFailedCallback(error);
      } else {
        store.dispatch({
          type: ProfileConfigConstants.UPDATE_DEPENDENT,
          payload: response
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      saveFailedCallback && saveFailedCallback(error?.response?.validationErrors.length > 0 ? error?.response?.validationErrors : error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const deleteDependent = (
  dependentId: number,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  ProfileApiCaller.deleteDependent(dependentId)
    .then((response) => {
      if (response?.errorMessage) {
        const error: any = [];
        error.push(response?.errorMessage);
        saveFailedCallback && saveFailedCallback(error);
      } else {
        store.dispatch({
          type: ProfileConfigConstants.UPDATE_PERSONAL_INFO,
          payload: response
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const AddDependent = (
  dependent: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: any) => void // @TODO - Remove Callback
) => {
  ProfileApiCaller.AddNewDependent(dependent)
    .then((response) => {
      if (response?.errorMessage) {
        const error: any = [];
        error.push(response?.validationErrors ? response?.validationErrors : response?.errorMessage);
        saveFailedCallback && saveFailedCallback(error);
      } else {
        store.dispatch({
          type: ProfileConfigConstants.UPDATE_DEPENDENT,
          payload: response
        });
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      saveFailedCallback && saveFailedCallback(error && error?.response?.data?.validationErrors?.length > 0 ? error?.response?.data?.validationErrors : [error?.message]);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryDependents = () => {
  ProfileApiCaller.getSummaryDependents().then((response) => {
    if (response && response?.statusCode === 200) {
      store.dispatch({
        type: ProfileConfigConstants.GET_SUMARY_DEPENDENTS,
        payload: response
      });
    } else {
      showError(response?.errorMessage);
    }
  });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryPersonal = () => {
  ProfileApiCaller.getSummaryPersonal().then((response) => {
    if (response && response?.statusCode === 200) {
      store.dispatch({
        type: ProfileConfigConstants.GET_SUMMARY_PERSONAL,
        payload: response
      });
    } else {
      showError(response?.errorMessage);
    }
  });
  return {
    type: CommonConstants.NO_CHANGE
  };
};
