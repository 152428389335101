import { BenefitConstants, CommonConstants, EventConfigConstants, FlexConstants, PageConfigConstants } from "../../constants";
import { BenefitApiCaller } from "../../middlewares/BenefitApiCaller";
import store from "../../store/store";
import { getMainMenuContent, progressBarIndicator, setMenu2Flag, showError } from "../commonActions";
import { deviceType } from "../../constants/PageSettings";

/*Benefit Wellness Action STRTS HERE*/
export const getWellness = async () => {
  try {
    const response = await BenefitApiCaller.getBenefitWellnessInfos();

    store.dispatch({
      type: BenefitConstants.GET_WELLNESS,
      payload: response
    });
    const menu2Flag = store.getState().common.menu2Flag;
    if (menu2Flag && (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent"))) {
      store.dispatch(await getMainMenuContent(true));
      store.dispatch(await getShowFlex());
      store.dispatch(setMenu2Flag(false));
    }
  } catch (error) {
    error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updateWellness = async (savedCallback?: CallableFunction) => {
  const wellnessQuestionAnswers = store.getState().benefit.benefitWellness.result;

  try {
    const response = await BenefitApiCaller.updateBenefitWellness(wellnessQuestionAnswers);

    if (response?.statusCode === 304 && response?.errorMessage) {
      savedCallback && savedCallback();
    }
    store.dispatch({
      type: BenefitConstants.UPDATE_WELLNESS,
      payload: response
    });
  } catch (error) {
    error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

/*Enrollment Header means to get flex Action STRTS HERE*/
export const getShowFlex = async () => {
  //console.log("benefitid",benefitid)
  await BenefitApiCaller.getShowFlexApi()
    .then((response) => {
      store.dispatch({
        type: BenefitConstants.GET_SHOW_FLEX,
        payload: response
      });

      if (response?.statusCode === 200) {
        const headerInfo = {
          firsName: response?.firsName,
          lastName: response?.lastName,
          divisionName: response?.divisionName,
          headerEffectiveDate: response?.headerEffectiveDate,
          effectiveDateText: response?.effectiveDateText
        };

        store.dispatch({
          type: PageConfigConstants.SET_HEADER_INFO,
          payload: headerInfo
        });
      }

      // savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      // console.log("error: ", error);
      error && showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

//Benefit
export const setMultiBenefitOption = (
  benefitURL: string,
  optionId: number,
  save: boolean,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
  // saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  const [benefitName, benefitid] = benefitURL.split("/");
  const reqParams = { optionID: optionId };
  if (benefitid && parseInt(benefitid) && save) {
    const paramProp = benefitName.toLowerCase() === "package" ? "packageGroupID" : "benefitID";
    reqParams[paramProp] = benefitid;
  }
  BenefitApiCaller.setMultiBenefitOption(benefitName, reqParams)
    .then(async (response) => {
      if (response?.statusCode === 200) {
        // await trackPromise(dispatch(getMultiBenefits()));
        await getMultiBenefits();
        store.dispatch(await getShowFlex());
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      console.log("error", error);
      // saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getMultiBenefits = async () => {
  const benefitList: any = [];
  try {
    const { benefitUrlList }: any = store.getState().benefit;

    for (const [, benefit] of benefitUrlList?.entries()) {
      // console.log(`benefit-${index}: ${benefit}`);
      const benfitData = await getBenefit(benefit);
      benefitList.push(benfitData);
    }

    if(deviceType ==="Desktop"){
    store.dispatch({
      type: BenefitConstants.GET_MULTI_BENEFIT_DATA,
      payload: benefitList
    });
    store.dispatch(await getSmokerContent());
    store.dispatch(await progressBarIndicator());
  }
  } catch (error) {
    console.log("error", error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getBenefit = async (benefiturl: any) => {
  const [benefitName, benefitid] = benefiturl?.split("/");
  const benefitList: any = [];
  const paramName = benefitName?.toLowerCase() === "package" ? "packageGroupID" : "benefitid";
  if (benefitid && parseInt(benefitid)) {
    try {
      const response = await BenefitApiCaller.getBenefitWithURL(benefitName, paramName, benefitid);
      if(deviceType!=="Desktop"){
        store.dispatch({
          type: BenefitConstants.BENEFIT_URL_LIST,
          payload: !Array.isArray(benefiturl) && [benefiturl]
        });
      response && benefitList.push(response);
      store.dispatch({
        type: BenefitConstants.GET_SINGLE_BENEFIT_DATA,
        payload: benefitList
      });
      const {isDependentSmokerStatusRequired,isEmployeeSmokerStatusRequired}:any=response;
      (isDependentSmokerStatusRequired || isEmployeeSmokerStatusRequired) && store.dispatch(await getSmokerContent());
      store.dispatch(await progressBarIndicator());
    }
      return response;
    } catch (error: any) {
      error && showError(error);
    }
    return {
      type: CommonConstants.NO_CHANGE
    };
  }
};

export const saveWaiverForm = (
  type: boolean | true,
  packageWaiverObj: any | [],
  benefitURL = "",
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  const [benefitName, benefitid] = benefitURL?.split("/");
  // const [benefitName, benefitid] = benefitURL
  // ? benefitURL.split("/")
  // : 'getState().userReducer.pageSetting.link.split("/")';
  let reqParams: any = {};
  if (benefitid && parseInt(benefitid)) {
    if (benefitName.toLowerCase() !== "package") {
      const { waiverReason, insuranceCompany, policyNumber } = packageWaiverObj[0];
      reqParams = {
        waiverReason: waiverReason,
        insuranceCompany: insuranceCompany,
        policyNumber: policyNumber,
        benefitID: benefitid
      };
    } else if (benefitName.toLowerCase() === "package") {
      reqParams = packageWaiverObj?.map((item) => ({
        ...item,
        packageGroupId: benefitid
      }));
      if (type && packageWaiverObj.length === 1) reqParams = reqParams[0];
    }
  }
  BenefitApiCaller.saveWaiverForm(benefitName, reqParams, type)
    .then(async (response) => {
      if (response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.SAVE_WAIVER_FORM,
          payload: response
        });

        benefitURL && getMultiBenefits();
        store.dispatch(await getShowFlex());
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      console.log("error", error);
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const removeWaiver = (
  benefitURL: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  BenefitApiCaller.removeWaiver(benefitURL)
    .then(async (response) => {
      if (response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.REMOVE_WAIVER,
          payload: response
        });
        getMultiBenefits();
        store.dispatch(await getShowFlex());
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      console.log("error", error);
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const removeEOIOption = (
  benefitURL: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  BenefitApiCaller.removeEOIOption(benefitURL)
    .then(async (response) => {
      if (response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.REMOVE_EOI,
          payload: response
        });
        if (benefitURL) getMultiBenefits();
        store.dispatch(await getShowFlex());
        savedCallback && savedCallback(response);
      }
    })
    .catch((error: any) => {
      console.log("error", error);
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const saveChangeDependentCoverage = (
  dependentsThisPlan,
  dependentsOtherPlan,
  benefitURL = "",
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  const [benefitName, benefitid] = benefitURL?.split("/");
  //: 'getState().userReducer.pageSetting.link.split("/")';
  //const packageGroupID = "packageGroupID";
  const reqParams = {
    dependentsThisPlan,
    dependentsOtherPlan
  };
  if (benefitName.toLowerCase() === "package") {
    reqParams["packageGroupID"] = parseInt(benefitid);
  }
  BenefitApiCaller.saveChangeDependentCoverage(benefitName, reqParams)
    .then(async (response) => {
      if (response && response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.PUT_DEPENDED_COVERAGE,
          payload: response
        });
        benefitURL && getMultiBenefits();
        store.dispatch(await getShowFlex());
        savedCallback && savedCallback(response);
      } else {
        const message: any = response?.errorMessage ? response.errorMessage : response.errorMessage || response.statusText;
        //savedCallback && savedCallback(response);
        saveFailedCallback && saveFailedCallback(message);
        //dispatch({ type: FAILURE, payload: { message } });
      }
    })
    .catch((error: any) => {
      console.log("error", error);
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSmokerContent = async () => {
  await BenefitApiCaller.getSmokerContent()
    .then((response) => {
      //if (response?.status === 200) {
      store.dispatch({
        type: BenefitConstants.GET_SMOKER_CONTENT,
        payload: response
      });
      // savedCallback && savedCallback(response);
      // }
    })
    .catch((error: any) => {
      console.log("error", error);
      // saveFailedCallback && saveFailedCallback(error);
      showError(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const saveBenefitsWithSmoker = (
  benefitOptions: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  BenefitApiCaller.saveSmokerBenefit(benefitOptions)
    .then(async (response) => {
      if (response?.statusCode === 500) {
        const message = response?.errorMessage;
        saveFailedCallback && saveFailedCallback(message);
      }
      await getMultiBenefits();
      store.dispatch(await getShowFlex());
      savedCallback && savedCallback(response);
    })
    .catch((error: any) => {
      console.log("error", error);
      saveFailedCallback && saveFailedCallback(error);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};


export const saveFlex = async (
  flexGroupID: number,
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  const flexDollars = store.getState().benefit.flexDollars;

  const flexItemList: any = [];

  flexDollars.benefitFlexes.forEach((benefit: any) => {
    flexItemList.push({
      flexBenefitID: benefit.benefitID,
      flexAmount: benefit.flexAmount.toString() === "" ? "0" : benefit.flexAmount.toString()
    });
  });

  console.log("POST FLEX \n", flexGroupID, flexItemList);

  try {
    const response = await BenefitApiCaller.saveFlexBenefit(flexGroupID, flexItemList);
    if (response?.result !== "Failed" && response.statusCode === 200) {
      store.dispatch({
        type: FlexConstants.SAVE_FLEX,
        payload: response
      });
    } else {
      const message = response?.result || response.errorMessage;
      message && showError(message);
      saveFailedCallback && saveFailedCallback();
    }
  } catch (error) {
    error && showError(error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getFlex = async (groupID: number, callProgress: boolean) => {
  try {
    const response = await BenefitApiCaller.getFlexBenefit(groupID);
    if (response?.statusCode === 200) {
      store.dispatch({
        type: FlexConstants.GET_FLEX,
        payload: response
      });
      if (callProgress) store.dispatch(await progressBarIndicator());
      const menu2Flag = store.getState().common.menu2Flag;
      const localFlag = localStorage.getItem("isReview") || localStorage.getItem("lifeEvent");

      if (menu2Flag && localFlag) {
        store.dispatch(await getMainMenuContent(true));
        store.dispatch(await getShowFlex());
        store.dispatch(setMenu2Flag(false));
      }
    } else {
      response && showError(response?.errorMessage);
    }
  } catch (error) {
    error && showError(error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const updateFlex = async (payload: any) => {
  console.log("FLEX PUT \n", payload);
  try {
    const response = await BenefitApiCaller.updateFlexBenefit(payload);
    store.dispatch({
      type: FlexConstants.UPDATE_FLEX,
      payload: response
    });
    store.dispatch(await getShowFlex());
  } catch (error) {
    error && showError(error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const flexReallocate = async (payload: any) => {
  try {
    const response = await BenefitApiCaller.reallocateFlexBenefit(payload);
    store.dispatch({
      type: FlexConstants.RELOCATE_FLEX,
      payload: response
    });
  } catch (error) {
    error && showError(error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getBenefitSummary = (callProgress: boolean) => {
  BenefitApiCaller.getBenefitSummary()
    .then(async (response) => {
      if (response && response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.GET_BENEFIT_SUMMARY,
          payload: response
        });

        callProgress && store.dispatch(await progressBarIndicator());
      } else {
        response?.errorMessage && showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const confirmEnrollment = (
  successCallback?: () => void, // @TODO - Remove Callback
  failedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  BenefitApiCaller.confirmEnrollment()
    .then((response) => {
      if (response && response?.enrollmentReturnCode === 0) {
        store.dispatch({
          type: BenefitConstants.GET_BENEFIT_SUMMARY,
          payload: response
        });
        if (successCallback) successCallback();
        // localStorage.setItem("logOffCheck", true);
        // dispatch(setHeaderNavURL(true));
      } else {
        showError(response?.errorMessage);
        failedCallback && failedCallback(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryBenefits = (callProgress: boolean) => {
  BenefitApiCaller.getSummaryBenefits()
    .then(async (response) => {
      if (response) {
        store.dispatch({
          type: BenefitConstants.GET_SUMMARY_BENEFITS,
          payload: response
        });
        callProgress && store.dispatch(await progressBarIndicator());
      } else {
        response?.errorMessage && showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getSummaryFlex = () => {
  BenefitApiCaller.getSummaryFlex()
    .then((response) => {
      if (response && response?.statusCode === 200) {
        store.dispatch({
          type: BenefitConstants.GET_SUMMARY_FLEX,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getEnrollmentFooter = async (callback: CallableFunction) => {
  try {
    const response = await BenefitApiCaller.getEnrollmentFooter();
    if (response?.enrollmentReturnCode === 0) {
      store.dispatch({
        type: EventConfigConstants.GET_ENROLMENT_FOOTER,
        payload: response
      });
      if (callback) {
        callback();
      }
    } else {
      showError(response?.errorMessage);
    }
  } catch (error) {
    error && showError(error);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const setMiniCartInitialState = async (flag: boolean) => {
  try {
    store.dispatch({
      type: EventConfigConstants.SET_MINI_CART_INITIAL_STATE,
      payload: flag
    });
  } catch (exception) {
    console.log("exception found", exception);
  }
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const focusInitializing = async () => {
  try {
    store.dispatch({
      type: EventConfigConstants.FOCUS_INITIALIZING,
      payload: !store.getState().event.focusInitializing
    });
  } catch (exception) {
    console.log("exception found", exception);
  }
};
