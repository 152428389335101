// Imports
// import { ProviderApiCaller } from '../../middlewares';
// import { LoggerUtils } from '../../utils';
// import { CacheableDataConstants, ProviderConstants } from "../actionTypes";
// import { CommonConstants } from "../../constants";
import { Location, NavigateFunction } from "react-router-dom";
import _ from "underscore";
import {
  AuthenticationConstants,
  BenefitConstants,
  CommonConstants,
  ContentDataConstants,
  CoverageConfigConstants,
  EventConfigConstants,
  FileUploadConfigConstants,
  PageConfigConstants,
  StatusConfigConstants,
  contentResource,
  deviceType
} from "../../constants";
import { Dependents, Health, Life, LifeChangeEvent, Reviewpersonalprofile, Welcome, Wellness } from "../../constants/PageSettings";
import { BenefitApiCaller } from "../../middlewares/BenefitApiCaller";
import { CommonApiCaller } from "../../middlewares/CommonApiCaller";
import { store } from "../../store/";
import { ObjectUtils, StringUtils, USER_TOKEN } from "../../utils";
import { getDependents, getProfileContent, getSummaryDependents, getSummaryPersonal } from "../ProfileActions";
import { EnumScreenObject } from "../actionTypes";
import { finishEnrollmentForms, getBeneficiaries, getBeneficiariesAll, getSummaryBeneficiaries, reallocateMultiBenefit } from "../beneficiaryActions";
import { getBenefit, getBenefitSummary, getFlex, getMultiBenefits, getShowFlex, getSummaryBenefits, getSummaryFlex, getWellness, saveFlex, updateWellness } from "../benefitActions";
import { getTFARegContent, getTFARegSelection, getTwoFASettings, getVerificationContent, logoff } from "../AuthActions";
import { getDirectBilling } from "../directBillingActions";

//contentResource
// Functions

/**
 * This function will load the list of Role.
 *
 * @param savedCallback
 * The function to be fired when the list has arrived.
 *
 * This function will be provided with the
 * actionparam of the data to be fetched.
 *
 * @param saveFailedCallback
 * The function to be fired when the fetching of
 * the data failed.
 *
 * This function will be provided with the
 * error-id for the failure.
 *
 */

export const setBasePageTitle = async (pageName: any) => {
  const screens = store.getState()?.common?.contentMainMenu;
  let screenTitle = "";
  let stepDescription = "";

  const keys = Object.keys(screens);
  store.dispatch({
    type: PageConfigConstants.SET_BASE_PAGE_ITEM,
    payload: pageName
  });

  keys.some((item) => {
    const subMenus = screens[item].subMenus;

    if (subMenus && Object.keys(subMenus).length !== 0) {
      if (screens[item].subMenus[pageName.name] !== undefined) {
        screenTitle = screens[item].display;
        stepDescription = screens[item].stepDescription;
        return true; // Exit loop
      }
    } else if (item === pageName.name) {
      screenTitle = screens[item].display;
      stepDescription = screens[item].stepDescription;
      return true; // Exit loop
    }

    return false; // Continue loop
  });

  store.dispatch({
    type: PageConfigConstants.SET_BASE_PAGE_TITLE,
    payload: screenTitle
  });
  store.dispatch({
    type: PageConfigConstants.SET_STEP_DESCRIPTION,
    payload: stepDescription
  });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

// export const setBaseBeneficiaryPageTitle =
//   (pageTitle:any) => () => {

//     store.dispatch({
//       type: PageConfigConstants.SET_BASE_PAGE_TITLE,
//       payload: pageTitle
//     });
//     return {
//       type: CommonConstants.NO_CHANGE
//     };
//   };

export const getClientById = (
  _clientName: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  USER_TOKEN.setClientName(_clientName);
  CommonApiCaller.clientDetails(_clientName)
    .then((response) => {
      if (response.statusCode !== 204) {
        store.dispatch({
          type: ContentDataConstants.GET_CLIENT_NAME,
          payload: _clientName
        });
        store.dispatch({
          type: ContentDataConstants.GET_CLIENT_DETAILS_CONTENT,
          payload: response?.result
        });
        store.dispatch({
          type: ContentDataConstants.SHOW_WARNING_MESSAGE,
          payload: response?.result?.ShowWarningMessage
        });
        // localStorage.setItem("style", JSON.stringify(response?.result));
        savedCallback && savedCallback(response);
      } else {
        USER_TOKEN.removeClientName();
        saveFailedCallback && saveFailedCallback(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const enableApiLoader = async () => {
  store.dispatch({
    type: ContentDataConstants.GET_API_LOADER,
    payload: ""
  });
};

export const languageSwitch = async (location: Location<any>) => {
  let path = "";
  const { isSignedIn, needTwoFAValidation } = USER_TOKEN.get();

  const { pathname, search } = location;
  if (search.split("?").length > 1) {
    path = search?.split("?")[1];
    const urlParam = new URLSearchParams(path);
    path = urlParam.get("dateString") || path;
    const groupID = urlParam.get("GroupID") ?? "";
    groupID && store.dispatch(await getFlex(parseInt(groupID), true));
  }

  if (isSignedIn && !needTwoFAValidation) {
    if (localStorage.getItem("isReview") || localStorage.getItem("lifeEvent")) {
      store.dispatch(await getMainMenuContent(true));
    } else {
      store.dispatch(await getMainMenuContent(false));
    }
  }
  store.dispatch(getHeaderImages());
  store.dispatch(getFooterContent());

  if (!_.isEmpty(store.getState().common?.putWelcomeResponse?.contentResource)) {
    store.dispatch(await putWelcomeMessages());
  }

  let reqURL = pathname.split("?").length > 1 ? pathname.split("?")[0] : pathname;
  reqURL = reqURL?.toLowerCase();
  const client = USER_TOKEN.get()?.client?.toLowerCase();
  console.log("reqURL: ", reqURL);

  switch (reqURL) {
    case `/${client}/sso`:
    case "/": {
      store.dispatch(await getMainMenuContent(false));
      store.dispatch(await getWelcomeContent());
      break;
    }
    case `/${client}/login`: {
      store.dispatch(getLoginContent());
      break;
    }
    case `/${client}/forgotpassword`: {
      store.dispatch(getForgotPasswordContent());
      break;
    }
    case "/changepassword": {
      store.dispatch(getTemporaryPasswordContent());
      break;
    }
    case "/logoff": {
      store.dispatch(await logoff());
      // store.dispatch(getLoginContent());
      break;
    }
    case "/reviewpersonalprofile": {
      store.dispatch(await getProfileContent(false));
      break;
    }
    case "/personalinformation": {
      store.dispatch(await getProfileContent(false));
      break;
    }
    case "/profile": {
      store.dispatch(await getProfileContent(false));
      break;
    }
    case "/dependents": {
      store.dispatch(await getDependents(""));
      break;
    }
    case "/dependent": {
      if (search === "?type=updatestudent") {
        const searchParams = new URLSearchParams(location?.search);
        const typeParam = searchParams.get("type") ?? "";
        store.dispatch(await getDependents(typeParam));
      } else {
        store.dispatch(await getDependents(""));
      }
      break;
    }
    /* case "/life": {
      store.dispatch(await getDependents(""));
      trackPromise(dispatch(getLifeBenefit()));
      break;
    } */
    case "/wellness": {
      store.dispatch(await getWellness());
      break;
    }
    case "/beneficiaries": {
      store.dispatch(getBeneficiaries());
      break;
    }
    case "/lifechangeevent": {
      store.dispatch(getLifeChangeContent());
      break;
    }
    case "/lifechangeeventclaim": {
      store.dispatch(getLifeChangeContent());
      break;
    }
    case "/reviewcoverage/currentcoverage": {
      store.dispatch(reviewCurrentCoverage(path));
      break;
    }
    case "/reviewcoverage/previouscoveragelist": {
      store.dispatch(pastCoverageList());
      break;
    }
    case "/coverage": {
      store.dispatch(reviewPastCoverage(path));
      break;
    }
    case "/content/footercontent": {
      const contentFooterPages = JSON.parse(sessionStorage.getItem("contentFooterPages") || "");
      if (contentFooterPages && contentFooterPages?.section) {
        store.dispatch(getContentFooterPage(contentFooterPages?.section));
      } else {
        store.dispatch(getContentFooterPage());
      }
      break;
    }
    case "/FileUpload/UploadedFilesInfo": {
      getFileUploadInfoContent;
      store.dispatch(getFileUploadInfoContent());
      store.dispatch(getUploadedFilesInfoContent());
      break;
    }
    case "/summary": {
      store.dispatch(getBenefitSummary(true));
      break;
    }
    case "/summary/personal": {
      store.dispatch(getSummaryPersonal());
      break;
    }
    case "/summary/dependents": {
      store.dispatch(getSummaryDependents());
      break;
    }
    case "/summary/beneficiaries": {
      store.dispatch(getSummaryBeneficiaries());
      break;
    }
    case "/enrollmentsummary": {
      store.dispatch(getSummaryBenefits(true));
      store.dispatch(getSummaryFlex());
      break;
    }
    case "/content/helpmenu": {
      store.dispatch(getCustomPopUpURL());
      break;
    }
    case "/beneficiary/all": {
      store.dispatch(getBeneficiariesAll());
      const benefitID = store.getState().beneficiary.flexBeneficiaryBenefitID;
      benefitID && store.dispatch(getBeneficiaries(benefitID, true));
      store.dispatch(finishEnrollmentForms());
      break;
    }
    case "/benefits": {
      store.dispatch(await getMultiBenefits());
      store.dispatch(await getShowFlex());
      break;
    }
    case "/multi-factor": {
      const { needTwoFARegistration, needTwoFAValidation } = USER_TOKEN.get();
      const twoFATypeID: any = sessionStorage.getItem("twoFATypeID");
      const isRegistration = JSON.parse(sessionStorage.getItem("isRegistration") || "false");
      console.log("twoFATypeID: ", twoFATypeID);
      console.log("isRegistration: ", isRegistration);

      if (needTwoFARegistration && isRegistration) {
        isRegistration && store.dispatch(getTFARegContent());
        if (twoFATypeID) {
          twoFATypeID && store.dispatch(await getTFARegSelection(twoFATypeID, isRegistration));
          const params = "isRegistration=" + isRegistration + "&selectedTwoFAType=" + twoFATypeID;
          store.dispatch(getVerificationContent(params));
        }
      }
      if (!needTwoFARegistration && needTwoFAValidation) {
        const params = "isRegistration=" + isRegistration + "&selectedTwoFAType=" + twoFATypeID;
        store.dispatch(getVerificationContent(params));
      }

      break;
    }
    /* case "/verification": {
      const params = "isRegistration=" + localStorage.getItem("isRegistration") + "&selectedTwoFAType=" + localStorage.getItem("TypeID");
      await dispatch(verificationContent(params));
      return;
    } */

    case "/account/twofasettings": {
      store.dispatch(getTwoFASettings());
      break;
    }

    case "/multipackagebeneficiaries": {
      store.dispatch(await getMultiBenefits());
      return;
    }

    case "/enrollmentconfirmation": {
      const formStatus=localStorage.getItem('formStatus')==="1"?1:0
      const queryParam = search?.indexOf("period") > -1 ? search : "";
      store.dispatch(await finishEnrollment(formStatus, queryParam));
      break;
    }

    case "/beneficiaryreallocate": {
      const pageName = store.getState().event.pageSetting.name;
      const benefitID = store.getState().beneficiary.flexBeneficiaryBenefitID;
      store.dispatch(reallocateMultiBenefit(pageName === "BENEFICIARIES" ? -1 : benefitID));
      return;
    }

    case "/directbilling":
    case "/directbilling/create":
    case "/directbilling/confirm":
      store.dispatch(await getDirectBilling());
      return;

    default: {
      console.log("do nothing");
    }
  }
};

/* Client Dynamic Stylesheet */
export const getClientStylesContent = async (flag: boolean) => {
  let clientStyles = JSON.parse(localStorage.getItem("clientStyles") || "{}");
  try {
    if (flag || !clientStyles) {
      const response = await CommonApiCaller.clientStyles();
      if (response?.statusCode === 200) {
        clientStyles = response.result;
        if (clientStyles) localStorage.setItem("clientStyles", JSON.stringify(clientStyles));
      }
    }
    ObjectUtils.modifyJsonObject(clientStyles);
  } catch (e) {
    console.log("exception occured ", e);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getLoginContent = (
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.loginContent()
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_LOGIN_CONTENT,
        payload: response
      });

      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getFooterContent = () => {
  CommonApiCaller.footerContent()
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_FOOTER_CONTENT,
        payload: response
      });
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getContentFooterPage = (
  _section?: any,
  savedCallback?: (data: any) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.getContentFooterPage(_section)
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_CONTENT_FOOTER_PAGE,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getForgotPasswordContent = () => {
  CommonApiCaller.getForgotPasswordContent()
    .then((response) => {
      if (response?.statusCode === 200) {
        store.dispatch({
          type: ContentDataConstants.GET_FORGOT_PASSWORD_CONTENT,
          payload: response
        });
      } else {
        showError(response?.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getTemporaryPasswordContent = () => {
  CommonApiCaller.getTemporaryPasswordContent()
    .then((response) => {
      if (response?.statusCode === 200) {
        store.dispatch({
          type: ContentDataConstants.GET_TEMPORARY_PASSWORD_CONTENT,
          payload: response
        });
      } else {
        showError(response.errorMessage);
      }
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getHeaderImages = () => {
  CommonApiCaller.headerImages()
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_HEADER_IMAGES_CONTENT,
        payload: response
      });

      store.dispatch({
        type: ContentDataConstants.GET_RESOURSE_CONTENT,
        payload: contentResource
      });
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const showError = (message: any) => {
  store.dispatch({
    type: StatusConfigConstants.FAILURE,
    payload: message
  });
};

export const showSuccess = (message: any) => {
  store.dispatch({
    type: StatusConfigConstants.SUCCESS,
    payload: message
  });
};

export const dissmiss = () => {
  store.dispatch({
    type: StatusConfigConstants.DISSMISS_MESSAGE,
    payload: ""
  });
};

export const loading = (isLoading: boolean) => {
  store.dispatch({
    type: StatusConfigConstants.IS_LOADER,
    payload: isLoading
  });
};

export const getCustomPopUpURL = (
  link?: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.customPopUpURL(link!)
    .then((response) => {
      store.dispatch({
        type: ContentDataConstants.GET_HELP_CONTENT,
        payload: response
      });

      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getWelcomeContent = async () =>
  //  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  //  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
  {
    await CommonApiCaller.welcomeContent()
      .then((response) => {
        store.dispatch({
          type: ContentDataConstants.GET_WELCOME_CONTENT,
          payload: response
        });
        store.dispatch({
          type: ContentDataConstants.GET_RESOURSE_CONTENT,
          payload: contentResource
        });
        if (response.result) {
          const headerInfo = {
            firsName: response.result.firsName,
            lastName: response.result.lastName,
            divisionName: response.result.divisionName
          };

          store.dispatch({
            type: PageConfigConstants.SET_HEADER_INFO,
            payload: headerInfo
          });
          store.dispatch({
            type: EventConfigConstants.NEXT_ENROLLMENT_STEP,
            payload: {}
          });
        }
        //  savedCallback && savedCallback(response);
      })
      .catch((error: Error) => {
        //  saveFailedCallback && saveFailedCallback(error?.message);
        showError(error?.message);
      })
      .finally(() => {
        if (localStorage.getItem("isReview") === "false") {
          localStorage.removeItem("isReview");
        }
        if (localStorage.getItem("hideButtons") === "true") {
          localStorage.removeItem("hideButtons");
        }
        if (localStorage.getItem("lifeEvent") === "true") {
          localStorage.removeItem("lifeEvent");
        }
        localStorage.removeItem("formStatus");
      });

    return {
      type: CommonConstants.NO_CHANGE
    };
  };

export const getMainMenuContent = async (isSecondMenu: boolean) => {
  try {
    const response = await CommonApiCaller.mainMenuContent(isSecondMenu);
    if (response?.statusCode === 200) {
      const mainMenu = response?.mainMenu;
      store.dispatch({
        type: ContentDataConstants.GET_MAIN_MENU_CONTENT,
        payload: mainMenu
      }); 

      const { basePageTitleItem, pageSetting } = store.getState().event;
      const pageTitle = basePageTitleItem || pageSetting || {};
      store.dispatch(await setBasePageTitle(pageTitle));
      store.dispatch(await setPageSetting(pageTitle?.name, false, true));
    }
  } catch (error: any) {
    /* const message = error?.message;
    showError(message); */
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const reviewCurrentCoverage = (sublink: string) => {
  CommonApiCaller.reviewCurrentCoverage(sublink)
    .then((response) => {
      store.dispatch({
        type: CoverageConfigConstants.REVIEW_CURRENT_COVERAGE,
        payload: response
      });
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const reviewPastCoverage = (sublink: string) => {
  CommonApiCaller.reviewPastCoverage(sublink)
    .then((response) => {
      store.dispatch({
        type: CoverageConfigConstants.REVIEW_PAST_COVERAGE,
        payload: response
      });
    })
    .catch((error: Error) => {
      showError(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const pastCoverageList = (
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.pastCoverageList()
    .then((response) => {
      store.dispatch({
        type: CoverageConfigConstants.REVIEW_PAST_COVERAGE_LIST,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const finishEnrollment = async (formStatus: number, queryParam: string) => {
  try {
    const response = await CommonApiCaller.finishEnrollment(formStatus, queryParam);
    localStorage.setItem("formStatus", formStatus.toString());
    if (formStatus !== 1) {
      store.dispatch(await getMainMenuContent(true));
    }
    /* console.log("Response: ",response); */
    store.dispatch({
      type: CoverageConfigConstants.FINISH_ENROLLMENT,
      payload: response
    });
  } catch (error) {
    // error && showError(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const downloadFinishedEnrollmentFile = (
  fileURL: string,
  fileName: string,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.downloadFinishedEnrollmentFile(fileURL)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}`;
      a.click();
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const pdfStatement = async (fileURL: string, fileName: string) => {
  try {
    const locale = store.getState().auth.locale;
    const headers = {
      Lang: `${locale}-CA`,
      "Content-Type": "application/json",
      Accept: "application/pdf"
    };

    // Fetch PDF data from the provided URL with headers
    const response = await CommonApiCaller.pdfStatement(fileURL, headers);

    const isIE = StringUtils.checkBrowser();
    if (isIE && (window.navigator as any).msSaveOrOpenBlob) {
      // Create a Blob from the PDF data
      const blob = new Blob([response], { type: "application/pdf" });
      // Save or open the Blob in Internet Explorer
      (window.navigator as any).msSaveBlob(blob, `${fileName}.pdf`);
    } else {
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.pdf`;
      document.body.appendChild(a); // Required for Firefox
      a.click();
      document.body.removeChild(a); // Clean up
    }
  } catch (error) {
    console.error("Error fetching PDF data:", error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const navigatesToLifeChangeEvent = (navigate: NavigateFunction) => {
  const screen = LifeChangeEvent;
  store.dispatch({
    type: PageConfigConstants.SET_BASE_PAGE_ITEM,
    payload: ""
  });
  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen }
  });
  navigate(`/${screen.link}`);
};

export const getLifeChangeContent = (
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.lifeChangeContent()
    .then((response) => {
      store.dispatch({
        type: EventConfigConstants.GET_LIFE_EVENT_CHANGE,
        payload: response
      });

      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const cancelEnrollment = (
  _screen: EnumScreenObject,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.deleteEnrollmentFooter()
    .then((response) => {
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: { _screen }
      });

      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const downloadUploadedFile = (
  fileID: any,
  fileName: any,
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.DownloadUploadDocuments(fileID, fileName)
    .then((response) => {
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      saveFailedCallback && saveFailedCallback(error?.message);
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getFileUploadInfoContent = (
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.getFileUploadInfoContent()
    .then((response) => {
      store.dispatch({
        type: FileUploadConfigConstants.FILE_UPLOAD_INFO,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      // console.log("error: ", error);
      error && saveFailedCallback && saveFailedCallback();
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getUploadedFilesInfoContent = (
  savedCallback?: (data: any) => void, // @TODO - Remove Callback
  saveFailedCallback?: (errorId?: string) => void // @TODO - Remove Callback
) => {
  CommonApiCaller.getUploadedFilesInfoContent()
    .then((response) => {
      store.dispatch({
        type: FileUploadConfigConstants.UPLOADED_FILES_INFO,
        payload: response
      });
      savedCallback && savedCallback(response);
    })
    .catch((error: Error) => {
      // console.log("error: ", error);
      error && saveFailedCallback && saveFailedCallback();
    });
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const setMenu2Flag = (flag: boolean) => (dispatch: (data: any) => void) => {
  dispatch({
    type: ContentDataConstants.SET_MENU2_FLAG,
    payload: flag
  });
};

export const putWelcomeMessages = async () => {
  const lifeChange = store.getState().common.putWelcomeRequestObject || {};
  try {
    let response;
    if (typeof lifeChange?.lifeEventDate === "object" && Object.keys(lifeChange?.lifeEventDate).length !== 0 && lifeChange?.lifeEventType !== "") {
      response = await CommonApiCaller.putWelcomeMessages({
        selectedLink: lifeChange.selectedLink,
        lifeEventDate: lifeChange.lifeEventDate,
        lifeEventType: lifeChange.lifeEventType
      });
    } else {
      response = await CommonApiCaller.putWelcomeMessages({
        selectedLink: lifeChange.selectedLink
      });
    }
    if (response && response?.statusCode === 200) {
      store.dispatch({
        type: ContentDataConstants.PUT_WELCOME_MESSAGES,
        payload: response
      });
    }
  } catch (e) {
    console.log("exception occured ", e);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const putWelcomeContent = async (navigate: NavigateFunction | null, _selectedlink: string | null, callback: CallableFunction | null, _isReview: any, lifeChange: any) => {
  const locale = store.getState().auth.locale;
  const headers = { Lang: `${locale}`, DeviceType: deviceType };

  try {
    store.dispatch(setMenu2Flag(false));

    const response = await CommonApiCaller.updateWelcomeContent(lifeChange ? { ...lifeChange } : { selectedLink: _selectedlink }, headers);

    let link = response?.navigateTo;

    if (link !== "" && !/EnrollmentFooter/.test(link)) {
      store.dispatch(await getMainMenuContent(true));
    }

    if (link && typeof link === "string" && link.split("/")?.length > 0 && (link.split("/")?.[0] === "VAC" || link.split("/")?.[0] === "Package")) {
      const prefix: string | undefined = link.split("/")?.[0] === "VAC" ? "VACATION" : "PACKAGE";
      link = `${prefix ?? ""}/${link.split("/")?.pop()}`;
    }

    const screens = store.getState().common.screens;
    let screen: any;
    let navLink: any;
    if (link.length > 0 && typeof link !== "string") {
      store.dispatch({
        type: BenefitConstants.BENEFIT_URL_LIST,
        payload: link
      });
      deviceType === "Desktop" && store.dispatch(await getMultiBenefits());
      link = link[0];
      if (deviceType === "Desktop") {
        _.each(screens, (item) => {
          if (item.link instanceof Array) {
            const obj = _.where(item.link, { actinLink: link?.toUpperCase() });
            if (obj && obj.length !== 0) {
              screen = item;
              navLink = "/Benefits";
              return;
            }
          }
        });
      } else {
        screen = _.where(screens, { link: link });
        screen = screen.length > 0 ? screen[screen.length - 1] : Welcome;
        navLink = `/${screen.link ? screen.link : !/EnrollmentFooter/.test(link) ? link : ""}`;
      }
    } else {
      screen = _.where(screens, { link: link });
      screen = screen?.length > 0 ? screen[screen.length - 1] : Welcome;

      navLink = `/${screen.link ? screen.link : !/EnrollmentFooter/.test(link) ? link : ""}`;
    }
    if (deviceType === "Desktop" && screens.length > 0 && screen === Welcome) {
      screen = _.where(screens, { link: link });
      screen?.length && store.dispatch(await setPageSetting(screen[0]?.name));
    }

    const benefit = deviceType !== "Desktop" ? await getBenefit(screen?.link || link) : {};
    store.dispatch(await setBasePageTitle(screen));
    /* console.log("screen inside func:", screen);
    console.log("navLink inside func:", navLink); */
    console.log("screen & benefit: ", screen, benefit);

    store.dispatch({
      type: EventConfigConstants.SET_PAGE_SETTING,
      payload: { screen, benefit }
    });
    store.dispatch({
      type: ContentDataConstants.IS_DISABLED,
      payload: screen.link ? screen.link : link
    });
    store.dispatch({
      type: ContentDataConstants.PUT_WELCOME_CONTENT,
      payload: response
    });

    const requestedObject = lifeChange || {
      selectedLink: _selectedlink,
      lifeEventDate: {},
      lifeEventType: ""
    };

    store.dispatch({
      type: ContentDataConstants.PUT_WELCOME_REQUESTED_OBJECT,
      payload: requestedObject
    });

    localStorage.setItem("hideButtons", "true");

    callback && callback(true);

    if (_isReview && _selectedlink === _isReview) {
      localStorage.setItem("isReview", "false");
    }
    if (lifeChange) {
      localStorage.setItem("logOffCheck", "false");
      setHeaderNavURL(false);
    } else {
      localStorage.setItem("logOffCheck", "true");
      setHeaderNavURL(true);
    }

    navigate && navigate(navLink);
  } catch (error: any) {
    showError(error?.message);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const languageChange = (locale: string) => {
  store.dispatch({
    type: AuthenticationConstants.CHANGE_LOCALE,
    payload: locale
  });
};

export const toggleSideBar = (isOpen: boolean) => {
  store.dispatch({
    type: AuthenticationConstants.TOGGLE_SIDEBAR,
    payload: isOpen
  });
};

export const proceedToNext = async (navigate: NavigateFunction, location: any, pageName: string) => {
  store.dispatch(setMenu2Flag(false));
  const { pageSetting } = store.getState()?.event;

  const currentScreen = pageSetting.constructor === Array && pageSetting?.length > 0 ? pageSetting[0] : pageSetting;

  if (currentScreen && ((currentScreen.link instanceof Array && location?.pathname?.toUpperCase() === "/BENEFITS")|| (typeof currentScreen.link === "string" && currentScreen.link.split("/").length > 1))) {
    const benefitUrlList = store.getState()?.benefit?.benefitUrlList;
    const benefitsList = store.getState()?.benefit?.benefitsLists;
    let saveBenefitSuccessAmout = 0;
    for (const [index, benefit] of benefitsList.entries()) {
      const [benefitName, benefitid] = benefitUrlList[index].split("/");
      const optionList = benefitName.toLowerCase() === "package" ? benefit.packageList : benefit.options;
      const selectedOption = _.where(optionList, { isSelected: true });
      const optionID = selectedOption.length ? selectedOption[0].optionID : "";
      const reqParamProp = (benefitName.toLowerCase() === "package" && "packageGroupID") || "benefitID";
      const selectedBenefitOption = {};
      if (benefitid && optionID) {
        selectedBenefitOption[reqParamProp] =benefitid;
        selectedBenefitOption["optionID"] = optionID;
      } else {
        selectedBenefitOption["benefitID"] = 100 + benefitid;
      }
      const responseSave = await BenefitApiCaller.saveBenefit(benefitName, selectedBenefitOption);
      if (responseSave?.errorMessage || responseSave?.canProceedToNextStep === false) {
        setFocusOnBenefitError(true);
        return;
      }
      saveBenefitSuccessAmout += 1;
    }
    if (saveBenefitSuccessAmout !== (benefitUrlList || []).length) {
      return;
    }
  }

  if (currentScreen && typeof currentScreen.link === "string" && currentScreen.link.split("?").length > 1) {
    const path = currentScreen.link.split("?")[1];
    const search: URLSearchParams = new URLSearchParams(path);
    const groupIDString = search.get("GroupID");
    // Provide a default value if GroupID is not present or not a valid integer
    const groupID = groupIDString !== null ? parseInt(groupIDString, 10) : -1;
    let shouldReturn = false;
    store.dispatch(
      await saveFlex(groupID, () => {
        shouldReturn = true;
      })
    );
    if (shouldReturn) return;
  }

  if (currentScreen && typeof currentScreen.link === "string" && currentScreen.link.toLowerCase() === "wellness") {
    let shouldReturn = false;

    await updateWellness(() => {
      shouldReturn = true;
    });

    if (shouldReturn) return;
  }

  let nextScreen: any = null;
  let navLink = "";

  const responseNext = await CommonApiCaller.nextEnrollmentStep();
  store.dispatch({
    type: EventConfigConstants.NEXT_ENROLLMENT_STEP,
    payload: responseNext
  });

  if (Array.isArray(responseNext?.nextEvent)) {
    if (deviceType === "Desktop") {
      if (pageName === Dependents?.name || currentScreen.name === Dependents?.name || (typeof currentScreen.link === "string" && currentScreen.link.toLowerCase() === "dependent")) {
        store.dispatch(await getMainMenuContent(true));
      }
      store.dispatch({
        type: BenefitConstants.BENEFIT_URL_LIST,
        payload: responseNext.nextEvent
      });
      store.dispatch(await getMultiBenefits());
      responseNext.nextEvent = responseNext.nextEvent[0];
      const screens = store.getState().common.screens;

      screens.forEach((item: { link: any[] }) => {
        if (Array.isArray(item.link)) {
          const obj = item.link.find((link: { actinLink: any }) => link?.actinLink === responseNext?.nextEvent?.toUpperCase());
          if (obj) {
            nextScreen = item;
            navLink = "/Benefits";
            return;
          }
        }
      });

      store.dispatch(await getShowFlex());
      store.dispatch(await setBasePageTitle(nextScreen ? nextScreen : Welcome));
      const benefit = {};
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: { screen: nextScreen ? nextScreen : Welcome, benefit }
      });
      store.dispatch({
        type: ContentDataConstants.IS_DISABLED,
        payload: (nextScreen || {}).link ? nextScreen.link : responseNext.nextEvent
      });

      navigate(navLink ? navLink : "/");
      return;
    } else {
      responseNext.nextEvent = responseNext.nextEvent[0];
    }
  }

  if (pageName === Dependents?.name || currentScreen.name === Dependents?.name || (typeof currentScreen.link === "string" && currentScreen.link.toLowerCase() === "dependent")) {
    //Update menu2 content to match API logic
    store.dispatch(await getMainMenuContent(true));
  }

  const screens = store.getState().common.screens;
  if (!pageName && currentScreen) {
    if (responseNext && typeof responseNext.nextEvent === "string" && responseNext.nextEvent.split("/").length > 0 && responseNext.nextEvent.split("/")[0] === "VAC") {
      responseNext.nextEvent = `VACATION/${responseNext.nextEvent.split("/").pop()}`;
    }

    if (responseNext && typeof responseNext.nextEvent === "string" && responseNext.nextEvent.split("/").length > 0 && responseNext.nextEvent.split("/")[0] === "Package") {
      responseNext.nextEvent = `PACKAGE/${responseNext.nextEvent.split("/").pop()}`;
    }

    screens.map((item: any) => {
      if (item?.link === responseNext.nextEvent) {
        nextScreen = item;
      }
      return true;
    });
  }
  navigate(`/${responseNext.nextEvent}`);
  //}
  if (pageName || nextScreen == null) {
    nextScreen = getNextPage(pageName ? pageName : currentScreen.name);
  }
  const benefit = deviceType !== "Desktop" ? await getBenefit(nextScreen.link ? nextScreen.link : responseNext.nextEvent) : {};
  
  deviceType !== "Desktop"  && store.dispatch({
    type: BenefitConstants.BENEFIT_URL_LIST,
    payload: !Array.isArray(nextScreen.link || responseNext.nextEvent) && [nextScreen.link || responseNext.nextEvent]
  });
  if (responseNext && responseNext.nextEvent) {
    store.dispatch(await getShowFlex());
  }

  store.dispatch(await setBasePageTitle(nextScreen));
  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen: nextScreen, benefit }
  });
  store.dispatch({
    type: ContentDataConstants.IS_DISABLED,
    payload: nextScreen.link ? nextScreen.link : responseNext.nextEvent
  });

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const proceedToPrevious = async (navigate: NavigateFunction, pageName: string) => {
  const responsePrevious = await CommonApiCaller.previousEnrollmentStep();
  const { pageSetting } = store.getState()?.event;
  const currentScreen = pageSetting;
  const { screens } = store.getState().common;

  let previousScreen: any = null;
  let link = responsePrevious?.previousEvent ?? "";

  if (link && typeof link !== "string") {
    console.log((responsePrevious || {} || {}).previousEvent, "--<");
    if (deviceType === "Desktop") {
      link = link[0];

      const screen = screens.find((item: { link: any[] }) => Array.isArray(item.link) && item.link.some((linkItem) => linkItem.actinLink === link.toUpperCase()));

      store.dispatch({
        type: BenefitConstants.BENEFIT_URL_LIST,
        payload: responsePrevious.previousEvent
      });
      store.dispatch({
        type: EventConfigConstants.SET_PAGE_SETTING,
        payload: { screen: screen, benefit: {} }
      });

      await setBasePageTitle(screen);
      store.dispatch(await getMultiBenefits());
      store.dispatch(setMenu2Flag(false));
      store.dispatch(await getMainMenuContent(true));

      navigate("/Benefits");
      return;
    } else {
      responsePrevious.previousEvent = responsePrevious.previousEvent[0];
    }
  }

  if (!pageName && currentScreen) {
    if (responsePrevious && typeof responsePrevious.previousEvent === "string" && responsePrevious.previousEvent.split("/").length > 0 && responsePrevious.previousEvent.split("/")[0] === "Package") {
      responsePrevious.previousEvent = `PACKAGE/${responsePrevious.previousEvent.split("/").pop()}`;
    }

    screens.map((item: any) => {
      if (item?.link === responsePrevious?.previousEvent) {
        previousScreen = item;
      }
      return true;
    });
  }

  navigate(`/${responsePrevious.previousEvent}`);

  if (previousScreen && responsePrevious.previousEvent) {
    store.dispatch(await getShowFlex());
  }

  if (pageName || previousScreen == null) {
    previousScreen = getPreviousPage(pageName ? pageName : currentScreen?.name);
  }

  //EXCEPTIONAL PREVIOUS
  if (pageName === Dependents?.name || pageSetting?.name === Dependents?.name || (typeof pageSetting?.link === "string" && pageSetting?.link.toLowerCase() === "dependent")) {
    enableNext();
    const navigationError = store.getState().event.nextEnrolStep.navigationError;
    if (navigationError !== null) setNavigationError(null);
  }
  const benefit = deviceType !== "Desktop" ? await getBenefit(previousScreen?.link || responsePrevious?.previousEvent) : {};

  store.dispatch(await setBasePageTitle(previousScreen));

  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen: previousScreen, benefit }
  });

  store.dispatch(setMenu2Flag(benefit ? false : true));
  // if (benefit !== undefined) {
  //   store.dispatch(await getMainMenuContent(true));
  // }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

/* export const changeLocale = (locale: any) => {
  store.dispatch({
    type: AuthenticationConstants.CHANGE_LOCALE,
    payload: locale
  });
}; */

export const setPageSetting = async (pageName: any, callMenu = false, noMultiUpdate = false, callback?: any) => {
  const screens = store.getState().common.screens;

  let screen = screens.filter((item: any) => item?.name === pageName?.toUpperCase());

  screen = screen?.length > 0 ? screen[screen?.length - 1] : Welcome;
  let benefit: boolean | undefined;
  if (screen?.link instanceof Array) {
    const benefitUrlList: any = [];
    screen.link?.map((item: any) => {
      benefitUrlList.push(item.actinLink);
    });
    store.dispatch({
      type: BenefitConstants.BENEFIT_URL_LIST,
      payload: benefitUrlList
    });
    if (!noMultiUpdate) {
      store.dispatch(await getMultiBenefits());
    }
    benefit = true;
  } else {
    benefit = await getBenefit(screen.link);
  }

  if (callMenu) {
    store.dispatch(setMenu2Flag(benefit ? false : true));
  }
  setBasePageTitle(screen);
  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen, benefit }
  });

  if (callback) callback();
  if (callMenu && benefit !== undefined) {
    store.dispatch(await getMainMenuContent(true));
    store.dispatch(await getShowFlex());
  }
  // }
};

export const navigatesTo = (navigate: NavigateFunction, link: any) => {
  link = link ? link.replace(/\//g, "") : link;
  const screens = store.getState().common.screens;
  let screen: any = _.where(screens, { link });
  if (screen.length === 0) console.log(`No screens found for ${link}, so lets see Welcome screen`);
  screen = screen.length > 0 ? screen[screen.length - 1] : Welcome;
  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen }
  });
  navigate(`/${screen?.link}`);
};

export const progressBarIndicator = async () => {
  try {
    const response = await CommonApiCaller.progressIndicator();
    store.dispatch({
      type: EventConfigConstants.GET_PROGRESS_INDICATOR,
      payload: response
    });
    loading(false);
  } catch (error) {
    // Handle error
    console.error("Error fetching progress indicator:", error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const setFocusOnBenefitError = (payload: boolean) => {
  store.dispatch({
    type: BenefitConstants.SET_FOCUS_ON_BENEFIT_ERROR,
    payload: payload
  });
};

export const getNextPage = (currentPage: string) => {
  currentPage = currentPage?.toLowerCase();
  console.log("Next CurrentPage: ", currentPage);
  switch (currentPage) {
    case "lifechangeevent":
      return Reviewpersonalprofile;
    case "reviewpersonalprofile":
      return Dependents;
    case "personalinformation":
      return Dependents;
    case "dependents":
      return Wellness;
    case "wellness":
      return Health;
    case "health":
      return Life;
    case "life":
      return Welcome;

    default:
      return Welcome;
  }
};
export const getPreviousPage = (currentPage: string) => {
  currentPage = currentPage.toLowerCase();
  console.log("Prev CurrentPage: ", currentPage);
  switch (currentPage) {
    case "Lifechangeeventclaim":
      return LifeChangeEvent;
    case "Lifechangeevent":
      return Welcome;
    case "personalinformation":
      return Welcome;
    case "dependents":
      return Reviewpersonalprofile;
    case "wellness":
      return Dependents;
    case "life":
      return Wellness;
    default:
      return Welcome;
  }
};

export const setHeaderNavURL = (isNavAllowed = true) => {
  store.dispatch({
    type: PageConfigConstants.SET_NAV_URL,
    payload: isNavAllowed ? "/" : ""
  });
};

export const cancelEnrollmentClick = async (navigate: NavigateFunction, callback: CallableFunction | null) => {
  const response = await CommonApiCaller.deleteEnrollmentFooter();
  console.log("User click on EnrollmentFooter/Cancel and result is:", response);
  const screen = Welcome;
  store.dispatch({
    type: EventConfigConstants.SET_PAGE_SETTING,
    payload: { screen }
  });
  if (callback) {
    callback();
  }
  localStorage.setItem("logOffCheck", "true");
  localStorage.removeItem("hideButtons");
  setHeaderNavURL(true);
  navigate(`/${screen.link}`);
  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const getPageSettingInfo = async () => {
  const pathName = location.pathname;
  let link = pathName?.split("?").length > 1 ? pathName?.split("?")[0] : pathName;
  link = link ? link.replace(/\//g, "") : link;
  console.log("link: ", link);
  try {
    const screens = store?.getState()?.common?.screens;

    let screen: any = _.where(screens, { link });

    screen = screen?.length ? screen[0] : screen;

    store.dispatch({
      type: EventConfigConstants.SET_PAGE_SETTING,
      payload: { screen }
    });
  } catch (error) {
    console.log(error);
  }

  return {
    type: CommonConstants.NO_CHANGE
  };
};

export const disableNext = () => {
  store.dispatch({
    type: EventConfigConstants.DISABLE_NEXT,
    payload: true
  });
};

export const enableNext = () => {
  store.dispatch({
    type: EventConfigConstants.DISABLE_NEXT,
    payload: false
  });
};

export const setNavigationError = (errorValue: null) => {
  store.dispatch({
    type: EventConfigConstants.SET_NAV_ERROR,
    payload: errorValue
  });
};
